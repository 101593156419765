import { ChainId, Token, WBNB } from '@pancakeswap/sdk'

export const egoldChainTokens = {
  wxau: WBNB[ChainId.EGOLD],
  dep: new Token(
    ChainId.EGOLD,
    '0x03cF012A580117d40a748d84aDa53758A45a5033',
    18,
    'DEP',
    'DefiPower',
    'https://www.defipower.com',
  ),
  usdt: new Token(
    ChainId.EGOLD,
    '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
    18,
    'USDT',
    'Tether USD',
  ),
}
